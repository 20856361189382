import React, { Component } from "react";
/* Import Components */
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Radio from "../components/Radio";
import Input from "../components/Input";
import Select from "../components/Select";
import disciplines from "../content/option_lists/disciplines";
import classifications from "../content/option_lists/classifications";
import DentalAuxiliaries from "../containers/DentalAuxiliaries";
import {
  handleInput,
  handleBlur,
  blurAll,
  validationCheck,
  grabUserInput,
  validationErrors,
  defaultValidator
} from "../Utils.js";

class BasicInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        classification: this.props.getData().classification,
        discipline: this.props.getData().discipline,
        license_number: this.props.getData().license_number
      },
      touched: {
        classification: false,
        license_number: false,
        discipline: false
      },
      errors: {
        classification: "",
        license_number: "",
        discipline: ""
      }
    };

    this.handleInput = handleInput.bind(this);
    this.handleBlur = handleBlur.bind(this);
    this.blurAll = blurAll.bind(this);
    this._grabUserInput = grabUserInput.bind(this);
    this._validationErrors = validationErrors.bind(this);
    this.validationCheck = validationCheck.bind(this);
    this.defaultValidator = defaultValidator.bind(this);
    this.isValidated = this.isValidated.bind(this);
    this.handleDiscipline = this.handleDiscipline.bind(this);
  }

  componentDidMount() {
    document.title = 'TPCO Provider Assessment - Basic Info';
    document.getElementById("page_title").innerHTML="TPCO Provider Assessment - Basic Info";
  }

  _validateData(data) {
    return {
      //email_valid: data.email !== "", // required: anything besides N/A
      license_number:
        data.license_number.length > 0 || data.discipline === "Other", // required: regex w3c uses in html5
      discipline: data.discipline !== "",
      classification: data.classification !== "" || data.discipline === "Other"
    };
  }

  isValidated() {
    return this.defaultValidator(false, this.handleDiscipline);
  }

  async handleDiscipline() {
    switch (this.state.data.discipline) {
      case "Other":
        this.props.jumpToStep(this.props.getLastStepIndex());
        return false;
      case "DH":
        this.props.addStep({
          name: "Dental Auxiliaries",
          component: (
            <DentalAuxiliaries
              getData={this.props.getData}
              updateProvider={this.props.updateProvider}
              key={"Dental Auxiliaries"}
            />
          )
        });
        return true;
      default:
        this.props.removeStep("Dental Auxiliaries");
        return true;
    }
  }

  render() {
    return (
      <>
        <Container>
          <Radio
            errorClassName={this.state.errors.discipline}
            title={"What is your discipline? *"}
            name={"discipline"}
            options={disciplines}
            value={this.state.data.discipline}
            handleChange={this.handleInput}
            onBlur={this.handleBlur}
            error={"Please select your discipline"}
            touched={this.state.touched.discipline}
          />{" "}
        </Container>
        {/*Discipline*/}
        {this.state.data.discipline !== "" &&
          this.state.data.discipline !== "Other" && (
            <Form.Row className={"container mx-auto"}>
              <Form.Group as={Col} xs={12} md={6}>
                <Select
                  errorClassName={this.state.errors.classification}
                  title={"Profession *"}
                  name={"classification"}
                  placeholder={"Select your classification"}
                  value={this.state.data.classification}
                  options={classifications[this.state.data.discipline]}
                  handleChange={this.handleInput}
                  onBlur={this.handleBlur}
                  error={"Please select your classification"}
                  touched={this.state.touched.classification}
                />{" "}
                {/* Profession */}
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6}>
                <Input
                  errorClassName={this.state.errors.license_number}
                  inputType={"text"}
                  name={"license_number"}
                  title={"License Number *"}
                  value={this.state.data.license_number}
                  placeholder={"Enter your license number"}
                  handleChange={this.handleInput}
                  onBlur={this.handleBlur}
                  error={"Please enter your license number"}
                  touched={this.state.touched.license_number}
                />{" "}
                {/* License Number */}
              </Form.Group>
            </Form.Row>
          )}
          {"* Items are required"}
      </>
    );
  }
}

export default BasicInfo;
