const dental_specialties = {
  "GDT": [
    { value: "Dentist", text: "Dentist" },
    { value: "General Practice", text: "General Practice" },
    { value: "Pediatric Dentistry", text: "Pediatric Dentistry" },
    { value: "Instructor/Faculty", text: "Instructor/Faculty" },
    { value: "Hospitalist", text: "Hospitalist" },
    { value: "Other", text: "Other" }
  ]
};
export default dental_specialties;
