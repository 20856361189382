/* Email Validation */
export const email_regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
/* Name Validation */
export const name_regex = new RegExp(/^[a-zA-ZÀ-ž- ]*$/);
/* Number Validation*/
export const number_regex = new RegExp(/^[0-9]*$/);
/* Street Address Validation */
export const street_address_regex = new RegExp(/^[-., A-Za-zÀ-ž0-9]*$/);
/* Zip Code Validation */
export const zip_regex = new RegExp(/^\d{5}$|^\d{5}-\d{4}$/);
/* Phone Validation */
export const phone_regex = new RegExp(/^(\([0-9]{3}\)\s*|[0-9]{3})[0-9]{3}-[0-9]{4}$/);
/* Percentage Validation */
export const percentage_regex = new RegExp(/^([0-9]|[1-9][0-9]?$|100)$/);
/* Weekly Hours Validation*/
export const weekly_hours_regex = new RegExp(/^([0-9]|[1-3][0-9]?$|^40)$/);
/* Days in Year Validation*/
export const days_in_year_regex = new RegExp(/^([0-9]|[1-9][0-9]|[1-2][0-9][0-9]|3[0-5][0-9]|36[0-5]?$)$/);
/* Minute in 4 Hours Validation*/
export const minutes_in_4hours_regex = new RegExp(/^([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-3][0-9]?$|^240)$/);

/* Multiselect Validation */
/* Select Validation */

