import site_schema from "./site_schema";

const provider_schema = {
  npi_validated: false,
  npi: "",
  last_name: "",
  first_name: "",
  middle_name: "",
  email: "",
  license_number: "",
  discipline: "",
  classification: "",
  dental_hygienists: "",
  dental_assistants: "",
  special_categories: [],
  status_changes: [],
  status_changes_other: "",
  submitter_name: "",
  submitter_title: "",
  comments: "",
  sites: [site_schema]
};
export default provider_schema;
