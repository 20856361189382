import React, { Component } from "react";

/* Import Components */
import Input from "../../components/Input";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
/* Import Functions */
import {
  handleSiteInput,
  handleBlur,
  blurAll,
  validationCheck,
  grabUserInput,
  validationErrors,
  defaultValidator,
  handleError
} from "../../Utils.js";
import { minutes_in_4hours_regex, days_in_year_regex } from "../../validation_expressions";

class SiteDetails2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        new_patient_wait_days: this.props.getSiteData()[this.props.currentSite]
          .new_patient_wait_days,
        established_patient_wait_days: this.props.getSiteData()[this.props.currentSite]
          .established_patient_wait_days,
        new_patient_wait_minutes: this.props.getSiteData()[this.props.currentSite]
          .new_patient_wait_minutes,
        established_patient_wait_minutes: this.props.getSiteData()[
          this.props.currentSite
        ].established_patient_wait_minutes
      },
      touched: {
        new_patient_wait_days: false,
        established_patient_wait_days: false,
        new_patient_wait_minutes: false,
        established_patient_wait_minutes: false
      },
      errors: {
        new_patient_wait_days: "",
        established_patient_wait_days: "",
        new_patient_wait_minutes: "",
        established_patient_wait_minutes: ""
      }
    };
    this.site_number = this.props.currentSite;
    this.handleInput = handleSiteInput.bind(this);
    this.handleBlur = handleBlur.bind(this);
    this.blurAll = blurAll.bind(this);
    this._grabUserInput = grabUserInput.bind(this);
    this._validationErrors = validationErrors.bind(this);
    this.validationCheck = validationCheck.bind(this);
    this.isValidated = defaultValidator.bind(this, true);
    this.handleError = handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'TPCO Provider Assessment - Site Details 2';
    document.getElementById("page_title").innerHTML="TPCO Provider Assessment - Site Details 2";
  }

  _validateData(data) {
    return {
      new_patient_wait_days: days_in_year_regex.test(data.new_patient_wait_days), // required: regex w3c uses in html5
      established_patient_wait_days: days_in_year_regex.test(data.established_patient_wait_days),
      new_patient_wait_minutes: minutes_in_4hours_regex.test(data.new_patient_wait_minutes),
      established_patient_wait_minutes: minutes_in_4hours_regex.test(data.established_patient_wait_minutes)
    };
  }

  render() {
    return (
      <>
        <Container className="pb-4">
          <h2 className="form-label"><span>
            How long is the average wait time (in days) for routine/non-urgent
            appointments at this site?
          </span></h2>
        </Container>
        <Form.Row>
          <Form.Group as={Col} xs={12} md={6}>
            <Input
              errorClassName={this.state.errors.new_patient_wait_days}
              inputType={"number"}
              name={"new_patient_wait_days"}
              title={"New Patients *"}
              value={this.state.data.new_patient_wait_days}
              placeholder={"0-365"}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please enter a number between 0 and 365"}
              touched={this.state.touched.new_patient_wait_days}
              min="0"
              max="365"
            />{" "}
            {/* Wait Days New Patients */}
          </Form.Group>
          <Form.Group as={Col} xs={12} md={6}>
            <Input
              errorClassName={this.state.errors.established_patient_wait_days}
              inputType={"number"}
              name={"established_patient_wait_days"}
              title={"Established Patients *"}
              value={this.state.data.established_patient_wait_days}
              placeholder={"0-365"}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please enter a number between 0 and 365"}
              touched={this.state.touched.established_patient_wait_days}
              min="0"
              max="365"
            />{" "}
            {/* Wait Days Establsihed Patients */}
          </Form.Group>
        </Form.Row>
        <Container className="pb-4">
          <h2 className="form-label"><span>
            How long is the average wait time (in minutes) once the patient
            arrives at this site?
          </span></h2>
        </Container>
        <Form.Row>
          <Form.Group as={Col} xs={12} md={6}>
            <Input
              errorClassName={this.state.errors.new_patient_wait_minutes}
              inputType={"number"}
              name={"new_patient_wait_minutes"}
              title={"New Patients *"}
              value={this.state.data.new_patient_wait_minutes}
              placeholder={"0-240"}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please enter a number between 0 and 240"}
              touched={this.state.touched.new_patient_wait_minutes}
              min="0"
              max="240"
            />{" "}
            {/* Wait Minutes New Patients */}
          </Form.Group>
          <Form.Group as={Col} xs={12} md={6}>
            <Input
              errorClassName={this.state.errors.established_patient_wait_minutes}
              inputType={"number"}
              name={"established_patient_wait_minutes"}
              title={"Established Patients *"}
              value={this.state.data.established_patient_wait_minutes}
              placeholder={"0-240"}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please enter a number between 0 and 240"}
              touched={this.state.touched.established_patient_wait_minutes}
              min="0"
              max="240"
            />{" "}
            {/* Wait Minutes Established Patients */}
          </Form.Group>
        </Form.Row>
        {"* Items are required"}
      </>
    );
  }
}

export default SiteDetails2;
