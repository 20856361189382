import "babel-polyfill";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import { render } from "react-dom";
import App from './App';
import * as serviceWoker from './serviceWorker';

render(<App />, document.getElementById("root"));
serviceWoker.register();

