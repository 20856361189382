import React from "react";

// Import components
import confirmService from "./components/confirmService";
import SiteSubSpecialty from "./containers/site containers/SiteSubSpecialty";

/* Import option arrays */
import medical_specialties from "./content/option_lists/medical_specialties";
import dental_specialties from "./content/option_lists/dental_specialties";
import mental_specialties from "./content/option_lists/mental_specialties";

export function handleInput(e) {
  let value = e.target.value;
  let name = e.target.name;
  value =
    e.target.type === "number" ? (value = parseInt(value)) : e.target.value;
  this.updateState = updateState.bind(this);
  value = this.updateState(name, value);
  this.props.updateProvider({ [name]: value });
}

export function handleSiteInput(e) {
  let value = e.target.value;
  let name = e.target.name;
  value = e.target.type === "number" ? parseInt(value) : value;
  this.updateState = updateState.bind(this);
  this.updateState(name, value);
  this.props.updateSite({ [name]: value }, this.site_number);
}

function updateState(name, value) {
  //check if input allows array of values (checkboxes, multi-select)
  if (Array.isArray(this.state.data[name])) {
    let newSelectionArray;
    //if value in array, remove from array
    if (this.state.data[name].indexOf(value) > -1) {
      newSelectionArray = this.state.data[name].filter(s => s !== value);
    } else {
      //add new value to array
      newSelectionArray = [...this.state.data[name], value];
    }
    //set value to array of values
    value = newSelectionArray;
  }
  //set the state of the parent container
  this.setState(
    ({ data }) => ({
      data: {
        ...data,
        [name]: value
      }
    }),
    () => this.validationCheck()
  );
  return value;
}

export function handleBlur(e) {
  let name = e.target.name;
  this.setState(
    ({ touched }) => ({
      touched: {
        ...touched,
        [name]: true
      }
    }),
    () => this.validationCheck()
  );
}

//set all inputs in container as "touched"
export function blurAll() {
  Object.keys(this.state.touched).map(key =>
    this.setState(
      ({ touched }) => ({
        touched: {
          ...touched,
          [key]: true
        }
      }),
      () => this.validationCheck()
    )
  );
}

export function grabUserInput() {
  return this.state.data;
}

export function validationCheck() {
  const userInput = this._grabUserInput(); // grab user entered vals
  const validateNewInput = this._validateData(userInput); // run the new input against the validator
  const errors = this._validationErrors(validateNewInput);
  this.setState(Object.assign({ data: userInput }, validateNewInput, errors));
}

export function validationErrors(val) {
  let errors;
  Object.keys(val).map(
    key =>
      (errors = {
        ...errors,
        [key]: !val[key] && this.state.touched[key] ? "is-invalid d-block" : ""
      })
  );
  return { errors };
}

export async function defaultValidator(site = false, callback) {
  const userInput = this._grabUserInput(); // grab user entered vals
  const validateNewInput = this._validateData(userInput);
  const updateFunction = () =>
    site
      ? this.props.updateSite(this._grabUserInput(), this.site_number)
      : this.props.updateProvider(this._grabUserInput());
  // if full validation passes then save to store and pass as valid
  if (
    Object.keys(validateNewInput).every(k => {
      return validateNewInput[k] === true;
    })
  ) {
    let result = true;
    //run callback function, if passed
    try {
      if (typeof callback == "function") {
        result = await callback();
      }
      updateFunction();
      return result;
    } catch (error) {
      return await handleError(error.message);
    }
  } else {
    // if anything fails then update the UI validation state but NOT the UI Data State
    this.blurAll();
    return false;
  }
}

export async function checkEmpty(data, confirmation_message) {
  if (data.length < 1) {
    return await _confirmNone(confirmation_message);
  } else {
    return true;
  }
}

export async function _confirmNone(confirmation_message) {
  const result = await confirmService.show({
    message: confirmation_message
  });
  return result;
}

export async function handleError(error) {
  await confirmService.show({
    title: "Error",
    message: error,
    confirm_button_text: "OK",
    alert: true,
    animation: false
  });
  return false;
}

export function getSpecialties() {
  switch (this.props.getData().discipline) {
    case "DH":
      return dental_specialties[this.props.getData().classification];
    case "MH":
      return mental_specialties[this.props.getData().classification];
    default:
      return medical_specialties[this.props.getData().classification];
  }
}

export function handleSubSpecialty(add) {
  if (add) {
    this.props.addSiteStep({
      name: "Site Sub Specialty",
      component: (
        <SiteSubSpecialty
          getSiteData={this.props.getSiteData}
          updateSite={this.props.updateSite}
          getData={this.props.getData}
          currentSite={this.props.currentSite}
          key={"Site" + this.props.currentSite}
        />
      )
    });
  } else {
    this.props.removeSiteStep("Site Sub Specialty");
  }
  return true;
}

export const fetch_retry = async (url, n, options) => {
  try {
    console.log("retry " + n);
    let response = await fetch(url, options);
    if (!response.ok)
    {
      throw Error("Failed to Fetch:\n" + url);
    }
    return response;
  } catch (err) {
    if (n === 1) throw err;
    return fetch_retry(url, n - 1, options);
  }
};
