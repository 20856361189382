import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const Radio = props => (
  <div className="form-group" name={props.name}>
    <div>
      <label id={props.name} className="form-label" >
        {props.title}
      </label>
    </div>
    {props.options
      .sort((a, b) => (a.value > b.value || a.value === "Other" ? 1 : -1))
      .map(option => (
        <Row key={option.value}>
          <Col xs={0} sm={1}></Col>
          <Col
            className="form-check form-check-inline mx-auto pl-4"
            sm={11}
            xs={12}
          >
            <label id={option.text.replaceAll(" ", "_")} htmlFor={option.text.replaceAll(" ", "_") + "_Radio"} className="form-check-label">
              <input
                tabIndex="2"
                className={"form-check-input"}
                id={option.text.replaceAll(" ", "_") + "_Radio"}
                name={props.name}
                aria-labelledby={props.name + " " + option.text.replaceAll(" ", "_")}
                onChange={props.handleChange}
                onBlur={props.onBlur}
                value={option.value}
                checked={option.value.toString() === props.value}
                type="radio"
              />
              {option.text}
            </label>
          </Col>
        </Row>
      ))}
    {props.error.length > 0 && props.touched &&
      <div className={"invalid-feedback " + props.errorClassName} role="alert">
        {" "}
        {props.error}
      </div>}
  </div>
);

export default Radio;
