import React, { Component } from "react";
//import Container from "react-bootstrap/Container";
import FormContainer from "./containers/FormContainer";
//import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "react-bootstrap/Navbar";
import NavbarBackground from "./content/images/nav-bar-background.jpg";
import Logo from "./content/images/dshs-logo.png";
import pkg from "../package.json";
import "./css/layout.css";
import "./css/component.css";

const navbar_styles = {
  backgroundImage: `url(${NavbarBackground})`
};

class App extends Component {
  constructor(props) {
    super(props);
    this.navElToFocus = React.createRef();
  }

  resetFocus = () => {
    this.navElToFocus.current.focus();
    this.navElToFocus.current.blur();   
  }
  
  render() {
    return (
      <>
        <header>
          <Navbar
            className="banner"
            bg="dark"
            style={navbar_styles}
            variant="dark"
          >
            <a href="https://dshs.texas.gov" ref={this.navElToFocus} tabIndex="1" >
              {" "}
              <img src={Logo} alt={"DSHS Logo"} />
            </a>
            <div>
              <h1 id='page_title'>{"TPCO Provider Assessment"}</h1>
            </div>
          </Navbar>
        </header>
        <main>
          <FormContainer resetFocus={() => this.resetFocus()} />
        </main>
        <footer className="footer_region">
          <ul className="row footer_links mx-auto">
            <li><a href="https://dshs.texas.gov/contact.shtm" tabIndex="5">Contact Us</a></li>
            <li><a href="http://dshs.texas.gov/policy.shtm" tabIndex="5">Site Policies</a></li>
            <li><a href="https://gov.texas.gov/organization/hsgd"
              rel="noopener noreferrer" tabIndex="5">
              Texas Homeland Security
                    </a></li>
            <li><a href="https://www.tsl.texas.gov/trail/index.html" tabIndex="5">
              Statewide Search
                    </a></li>
            <li><a href="https://oig.hhsc.texas.gov/report-fraud" tabIndex="5">
              Report Fraud, Waste, and Abuse
                    </a></li>
          </ul>
          <p className="version">Version: {pkg.version}</p>
        </footer>
      </>
    );
  }
}

export default App;
