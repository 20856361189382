const site_schema = {
    address: "",
    city: "",
    state: "",
    zip: "",
    county: "",
    email: "",
    phone: "",
    fax: "",
    specialty: "",
    specialty_other: "",
    specialty_other_percentage: null,
    sub_specialty: "",
    sub_specialty_other: "",
    sub_specialty_other_percentage: null,
    practice_type: "",
    practice_type_other:"",
    new_patients: null,
    homeless_percentage: null,
    native_american_percentage: null,
    migrant_farmworker_percentage: null,
    seasonal_migrant_farmworker_percentage: null,
    direct_care_hours: null,
    medicaid_percentage: null,
    sliding_fee_scale_percentage: null,
    annual_patient_visits: null,
    new_patient_wait_days: null,
    new_patient_wait_minutes: null,
    established_patient_wait_days: null,
    established_patient_wait_minutes: null
  }
  export default site_schema;