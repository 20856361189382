import React from "react";

const TextArea = props => (
    <div className="form-group">
      <label className="form-label">{props.title}</label>
      <textarea
        tabIndex="2"
        className={"form-control " + props.errorClassName}
        id={props.name}
        name={props.name}
        ref={props.ref}
        rows={props.rows}
        cols={props.cols}
        type={props.inputType}
        value={props.value}
        onChange={props.handleChange}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
      />
       <div className={'invalid-feedback'}> {props.error}</div>
    </div>
  );
  
  export default TextArea;