import React, { Component } from "react";
/* Import Components */
import Input from "../components/Input";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import confirmService from "../components/confirmService";
import { trackPromise } from "react-promise-tracker";

/* Import Functions */
import {
  handleInput,
  handleBlur,
  blurAll,
  validationCheck,
  grabUserInput,
  validationErrors,
  defaultValidator,
  handleError,
  fetch_retry
} from "../Utils.js";
import {
  email_regex,
  name_regex,
  number_regex
} from "../validation_expressions.js";

class Start extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      data: {
        npi_validated: this.props.getData().npi_validated,
        npi: this.props.getData().npi,
        last_name: this.props.getData().last_name,
        middle_name: this.props.getData().middle_name,
        first_name: "",
        email: this.props.getData().email
      },
      result: [],
      touched: {
        npi: false,
        last_name: false,
        email: false
      },
      errors: {
        npi: "",
        last_name: "",
        email: ""
      }
    };
    this.handleInput = handleInput.bind(this);
    this.handleBlur = handleBlur.bind(this);
    this.blurAll = blurAll.bind(this);
    this._grabUserInput = grabUserInput.bind(this);
    this._validationErrors = validationErrors.bind(this);
    this.validationCheck = validationCheck.bind(this);
    this.defaultValidator = defaultValidator.bind(this);
    this.handleError = handleError.bind(this);
    this.isValidated = this.isValidated.bind(this);
    this._validateNPI = this._validateNPI.bind(this);
    this.handleNpiInput = this.handleNpiInput.bind(this);
    this.handleValidResult = this.handleValidResult.bind(this);
  }

  componentDidMount() {
    document.title = 'TPCO Provider Assessment - Start';
    document.getElementById("page_title").innerHTML="TPCO Provider Assessment - Start";
  }

  _validateData(data) {
    return {
      npi: number_regex.test(data.npi) && data.npi.toString().length === 10, // required: anything besides N/A
      last_name: name_regex.test(data.last_name) && data.last_name.length > 0, // required: regex w3c uses in html5
      email: email_regex.test(data.email)
    };
  }

  handleNpiInput(e) {
    this.setState(({ data }) => ({
      data: {
        ...data,
        npi_validated: false
      }
    }));
    this.handleInput(e);
  }

  async isValidated() {
    return this.defaultValidator(
      false,
      this.state.data.npi_validated ? true : this._validateNPI
    );
  }

  async _validateNPI(userInput = this._grabUserInput()) {
    let validated = false;
    await trackPromise(
      fetch_retry(
        process.env.REACT_APP_API_URL +
          "/" +
          userInput.npi.trim() +
          "/" +
          userInput.last_name
            .toUpperCase()
            .trim()
            .replace(" ", "%20"),
        3
      )
        .then(function(response) {
          if (response.ok) {
            return response.json();
          }
          throw Error("Internal Server Error. Try again later.");
        })
        .then(async responseJson => {
          validated = await this.handleValidResult(responseJson);
        })
        .catch(async error => {
          validated = await this.handleError(error.message);
        })
    );
    return validated;
  }

  async handleValidResult(data) {
    let confirmed = false;
    let results = {};
    if (data.results.length > 0) {
      results = {
        first_name: data.results[0].basic.first_name,
        middle_name: data.results[0].basic.middle_name
          ? data.results[0].basic.middle_name
          : "",
        last_name: data.results[0].basic.last_name
      };
      confirmed = await confirmService.show({
        message:
          "Provider name: " +
          results.first_name +
          " " +
          results.middle_name +
          " " +
          results.last_name,
        animation: false
      });
      if (confirmed) {
        this.setState(({ data }) => ({
          data: {
            ...data,
            first_name: results.first_name,
            middle_name: results.middle_name,
            last_name: results.last_name,
            npi_validated: true
          }
        }));
      }
    } else {
      confirmed = await this.handleError("Provider not found!");
    }
    return confirmed;
  }

  render() {
    return (
      <>
        <Container className="pt-4 pb-4">
          <h2>Welcome</h2>
          <p>
            The Texas Primary Care Office (TPCO) requests your response to the
            Healthcare Provider Assessment for each Primary Care Physician,
            Psychiatrist, or Dentist in your office. The survey should only take
            approximately 5-10 minutes to complete, and is essential in
            collecting information used to determine Health Professional
            Shortage Area (HPSA) designations in your community. There are
            currently more than 30 federal and state programs which use shortage
            designation to determine eligibility.
          </p>
          <p>
            To begin, please provide your NPI number and last name so that we
            can verify your identity with NPPES (National Plan & Provider
            Enumeration System):
          </p>
        </Container>
        <Form.Row className={"container mx-auto"}>
          <Form.Group as={Col} xs={12} sm={6}>
            <Input
              errorClassName={this.state.errors.last_name}
              inputType={"text"}
              name={"last_name"}
              title={"Provider's Last Name *"}
              value={this.state.data.last_name}
              placeholder={"Last Name"}
              handleChange={this.handleNpiInput}
              onBlur={this.handleBlur}
              error={"Please enter your last name"}
              touched={this.state.touched.last_name}
            />{" "}
            {/* Name of the user */}
          </Form.Group>
          <Form.Group as={Col} xs={12} sm={6}>
            <Input
              errorClassName={this.state.errors.npi}
              inputType={"text"}
              name={"npi"}
              title={"Provider's NPI Number *"}
              value={this.state.data.npi}
              placeholder={"Enter your NPI number"}
              handleChange={this.handleNpiInput}
              onBlur={this.handleBlur}
              error={"Please enter your NPI number"}
              touched={this.state.touched.npi}
            />{" "}
            {/* NPI Number */}
          </Form.Group>
          <Form.Group as={Col} xs={12} md={6}>
            <Input
              errorClassName={this.state.errors.email}
              inputType={"email"}
              name={"email"}
              title={"Provider's Email *"}
              value={this.state.data.email}
              placeholder={"Enter your email"}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please enter your email"}
              touched={this.state.touched.email}
            />{" "}
            {/* Email */}
          </Form.Group>
        </Form.Row>
        {"* Items are required"}
      </>
    );
  }
}

export default Start;
