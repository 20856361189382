import React, { Component } from "react";

/* Import Components */
import Input from "../../components/Input";
/* Import Functions */
import {
  handleSiteInput,
  handleBlur,
  blurAll,
  validationCheck,
  grabUserInput,
  validationErrors,
  defaultValidator,
  handleError
} from "../../Utils.js";
import { percentage_regex, weekly_hours_regex } from "../../validation_expressions";

class SiteDetails1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        direct_care_hours: this.props.getSiteData()[this.props.currentSite]
          .direct_care_hours,
        medicaid_percentage: this.props.getSiteData()[this.props.currentSite]
          .medicaid_percentage,
        sliding_fee_scale_percentage: this.props.getSiteData()[this.props.currentSite]
          .sliding_fee_scale_percentage,
        annual_patient_visits: this.props.getSiteData()[this.props.currentSite]
          .annual_patient_visits
      },
      touched: {
        direct_care_hours: false,
        medicaid_percentage: false,
        sliding_fee_scale_percentage: false,
        annual_patient_visits: false
      },
      errors: {
        direct_care_hours: "",
        medicaid_percentage: "",
        sliding_fee_scale_percentage: "",
        annual_patient_visits: ""
      }
    };
    this.site_number = this.props.currentSite;
    this.handleInput = handleSiteInput.bind(this);
    this.handleBlur = handleBlur.bind(this);
    this.blurAll = blurAll.bind(this);
    this._grabUserInput = grabUserInput.bind(this);
    this._validationErrors = validationErrors.bind(this);
    this.validationCheck = validationCheck.bind(this);
    this.isValidated = defaultValidator.bind(this, true);
    this.handleError = handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'TPCO Provider Assessment - Site Details 1';
    document.getElementById("page_title").innerHTML="TPCO Provider Assessment - Site Details 1";
  }

  _validateData(data) {
    return {
      direct_care_hours: weekly_hours_regex.test(data.direct_care_hours), // required: regex w3c uses in html5
      medicaid_percentage: percentage_regex.test(data.medicaid_percentage),
      sliding_fee_scale_percentage: percentage_regex.test(data.sliding_fee_scale_percentage),
      annual_patient_visits: data.annual_patient_visits !== null
    };
  }

  render() {
    return (
      <>
        <Input
          errorClassName={this.state.errors.direct_care_hours}
          inputType={"number"}
          name={"direct_care_hours"}
          title={
            "How many hours per week do you provide Direct Patient Care at this site? *"
          }
          value={this.state.data.direct_care_hours}
          placeholder={"0-40"}
          handleChange={this.handleInput}
          onBlur={this.handleBlur}
          error={"Please enter a number between 0 and 40"}
          touched={this.state.touched.direct_care_hours}
          min="0"
          max="40"
        />{" "}
        {/* Direct Patient Care */}
        <Input
          errorClassName={this.state.errors.medicaid_percentage}
          inputType={"number"}
          name={"medicaid_percentage"}
          title={
            "What percentage of your patients at this site have Medicaid? *"
          }
          value={this.state.data.medicaid_percentage}
          placeholder={"0-100"}
          handleChange={this.handleInput}
          onBlur={this.handleBlur}
          error={"Please enter a number between 0 and 100"}
          touched={this.state.touched.medicaid_percentage}
          min="0"
          max="100"
        />{" "}
        {/* Medicaid */}
        <Input
          errorClassName={this.state.errors.sliding_fee_scale_percentage}
          inputType={"number"}
          name={"sliding_fee_scale_percentage"}
          title={
            "What percentage of your patients at this site use the Siliding Fee Scale (SFS)? *"
          }
          value={this.state.data.sliding_fee_scale_percentage}
          placeholder={"0-100"}
          handleChange={this.handleInput}
          onBlur={this.handleBlur}
          error={"Please enter a number between 0 and 100"}
          touched={this.state.touched.sliding_fee_scale_percentage}
          min="0"
          max="100"
        />{" "}
        {/* Sliding Fee Scale */}
        <Input
          errorClassName={this.state.errors.annual_patient_visits}
          inputType={"number"}
          name={"annual_patient_visits"}
          title={
            "What is the average number of patient visits a year at this site? *"
          }
          value={this.state.data.annual_patient_visits}
          placeholder={"Annual patient visits"}
          handleChange={this.handleInput}
          onBlur={this.handleBlur}
          error={"Please enter a number"}
          touched={this.state.touched.annual_patient_visits}
          min="0"
        />{" "}
        {/* Annual Patient Visits */}
        {"* Items are required"}
      </>
    );
  }
}

export default SiteDetails1;
