import React, { Component } from "react";
import { render } from "react-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

let resolve;
const defaultProps = {
  title: "Confirmation",
  message: "Are you sure?",
  confirm_button_text: "Confirm",
  cancel_button_text: "Cancel",
  alert: false,
  animation: true
};

class Confirm extends Component {
  static create(props = {}) {
    const containerElement = document.createElement("div");
    document.body.appendChild(containerElement);
    document.body.backgroundColor = "rgba(0, 0, 0, 0.4)";
    return render(<Confirm createConfirmProps={props} />, containerElement);
  }

  constructor() {
    super();

    this.state = {
      isOpen: false,
      showConfirmProps: {}
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.show = this.show.bind(this);
  }

  handleCancel() {
    this.setState({ isOpen: false });
    resolve(false);
  }

  handleConfirm() {
    this.setState({ isOpen: false });
    resolve(true);
  }

  show(props = {}) {
    const showConfirmProps = { ...this.props.createConfirmProps, ...props };
    this.setState({ isOpen: true, showConfirmProps });
    return new Promise(res => {
      resolve = res;
    });
  }

  render() {
    const { isOpen, showConfirmProps } = this.state;
    const {
      message,
      title,
      cancel_button_text,
      confirm_button_text,
      alert,
      animation
    } = showConfirmProps;
    let buttons;
    if (alert) {
      buttons = (
        <Button
          variant="warning"
          onClick={this.handleConfirm}
          id="confirm-button"
          autoFocus
        >
          {confirm_button_text || defaultProps.confirm_button_text}
        </Button>
      );
    } else {
      buttons = (
        <>
          <Button variant="secondary" onClick={this.handleCancel} id="cancel-button">
            {cancel_button_text || defaultProps.cancel_button_text}
          </Button>
          <Button
            variant="warning"
            onClick={this.handleConfirm}
            id="confirm-button"
            autoFocus
          >
            {confirm_button_text || defaultProps.confirm_button_text}
          </Button>
        </>
      );
    }
    return (
      <Modal show={isOpen} onHide={this.handleCancel} backdrop={'static'} keyboard={false} animation={animation && defaultProps.animation}>
        <Modal.Header closeButton>
          <Modal.Title>{title || defaultProps.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message || defaultProps.message}</Modal.Body>
        <Modal.Footer>{buttons}</Modal.Footer>
      </Modal>
    );
  }
}

export default Confirm;
