const special_categories_options = [
    { value: "nhsc", text: "National Health Service Corp" },
    { value: "resident_intern", text: "Resident/Intern" },
    { value: "j1_visa", text: "J-1 Visa Waiver Holder" },
    { value: "federal_provider", text: "Federal Provider"},
    { value: "loan_repayment", text: "State Loan Repayment/Scholarship" },
    { value: "locus_tenens", text: "Locus Tenens" },
    { value: "h1B_visa", text: "H-1B Visa Holder" },
    { value: "restricted_license", text: "Restricted License" }
    ]
export default special_categories_options