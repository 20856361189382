import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const CheckBox = props => (
  <div className="form-group" name={props.name}>
    <div>
      <label id={props.name} className="form-label">
        {props.title}
      </label>
    </div>
    {props.options.sort((a, b) => (a.name > b.name) || a.value === "Other" ? 1 : -1).map(option => (
      <Row key={option.value}>
        <Col xs={0} sm={1}></Col>
        <Col className={"form-check form-check-inline mx-auto pl-4"} xs={12} sm={11}>
          <label id={option.text.replaceAll(" ", "_")} htmlFor={option.text.replaceAll(" ", "_") + "_CheckBox"} className="form-check-label">
            <input
              tabIndex="2"
              className="form-check-input"
              id={option.text.replaceAll(" ", "_") + "_CheckBox"}
              name={props.name}
              aria-labelledby={props.name + " " + option.text.replaceAll(" ", "_")}
              onChange={props.handleChange}
              onBlur={props.onBlur}
              value={option.value}
              checked={props.selectedOptions.indexOf(option.value) > -1}
              type="checkbox"
            />
            {option.text}
          </label>
        </Col>
      </Row>
    ))}
    <div className={"invalid-feedback " + props.errorClassName}>
      {" "}
      {props.error}
    </div>
  </div>
);

export default CheckBox;
