const medical_specialties = {
  "FP": [
    { value: "Family Medicine", text: "Family Medicine" },
    { value: "Family Practice", text: "Family Practice" },
    { value: "Adolescent Medicine", text: "Adolescent Medicine" },
    { value: "Adult Medicine", text: "Adult Medicine" },
    { value: "Geriatric Medicine", text: "Geriatric Medicine" },
    { value: "Instructor/Faculty", text: "Instructor/Faculty" },
    { value: "Hospitalist", text: "Hospitalist" },
    { value: "Other", text: "Other" }
  ],
  "IM": [
    { value: "Internal Medicine", text: "Internal Medicine" },
    { value: "Adolescent Medicine", text: "Adolescent Medicine" },
    { value: "Geriatric Medicine", text: "Geriatric Medicine" },
    { value: "Instructor/Faculty", text: "Instructor/Faculty" },
    { value: "Hospitalist", text: "Hospitalist" },
    { value: "Other", text: "Other" }
  ],
  "OBG" : [
    { value: "Obstetrics", text: "Obstetrics" },
    { value: "Gynecology", text: "Gynecology" },
    { value: "Obstetrics & Gynecology", text: "Obstetrics & Gynecology" },
    { value: "Instructor/Faculty", text: "Instructor/Faculty" },
    { value: "Hospitalist", text: "Hospitalist" },
    { value: "Other", text: "Other" }
  ],
  "PD" : [
    { value: "Pediatrics", text: "Pediatrics" },
    { value: "Adolescent Medicine", text: "Adolescent Medicine" },
    { value: "Instructor/Faculty", text: "Instructor/Faculty" },
    { value: "Hospitalist", text: "Hospitalist" },
    { value: "Other", text: "Other" }

  ],
  "GP" : [
    { value: "General Practice", text: "General Practice" },
    { value: "Instructor/Faculty", text: "Instructor/Faculty" },
    { value: "Hospitalist", text: "Hospitalist" },
    { value: "Other", text: "Other" }
  ]
};
export default medical_specialties;
