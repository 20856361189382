import React, { Component } from "react";
/* Import Components */
import Select from "../components/Select";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import dental_auxiliaries from "../content/option_lists/dental_auxiliaries";
import {
  handleInput,
  handleBlur,
  blurAll,
  validationCheck,
  grabUserInput,
  validationErrors,
  defaultValidator
} from "../Utils.js";

class DentalAuxiliaries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        dental_assistants: this.props.getData().dental_assistants,
        dental_hygienists: this.props.getData().dental_hygienists
      },
      touched: {
        dental_assistants: false,
        dental_hygienists: false
      },
      errors: {
        dental_assistants: "",
        dental_hygienists: ""
      }
    };

    this.handleInput = handleInput.bind(this);
    this.handleBlur = handleBlur.bind(this);
    this.blurAll = blurAll.bind(this);
    this._grabUserInput = grabUserInput.bind(this);
    this._validationErrors = validationErrors.bind(this);
    this.validationCheck = validationCheck.bind(this);
    this.isValidated = defaultValidator.bind(this);
  }
  componentDidMount() {
    document.title = 'TPCO Provider Assessment - Dental Auxiliaries';
    document.getElementById("page_title").innerHTML="TPCO Provider Assessment - Dental Auxiliaries";
  }
  _validateData(data) {
    return {
      dental_assistants: data.dental_assistants !== "", // required: anything besides N/A
      dental_hygienists: data.dental_hygienists !== "" // required: regex w3c uses in html5
    };
  }

  render() {
    return (
      <>
        <Form.Row className={"container mx-auto"}>
          <Form.Group as={Col} xs={12} md={6}>
            <Select
              errorClassName={this.state.errors.dental_assistants}
              title={"How many Dental Assistants do you have? *"}
              name={"dental_assistants"}
              placeholder={"Number of Assistants"}
              options={dental_auxiliaries}
              value={this.state.data.dental_assistants}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please select an option"}
              touched={this.state.touched.dental_assistants}
            />{" "}
            {/* Assistants */}
          </Form.Group>
          <Form.Group as={Col} xs={12} md={6}>
            <Select
              errorClassName={this.state.errors.dental_hygienists}
              title={"How many Dental Hygienists do you have? *"}
              name={"dental_hygienists"}
              placeholder={"Number of Hygienists"}
              options={dental_auxiliaries}
              value={this.state.data.dental_hygienists}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please select an option"}
              touched={this.state.touched.dental_hygienists}
            />{" "}
            {/* Hygienists */}
          </Form.Group>
        </Form.Row>
        {"* Items are required"}
      </>
    );
  }
}

export default DentalAuxiliaries;
