import React, { Component } from "react";
//import StepZilla from "react-stepzilla";
import StepZilla from "../custom_node_modules/react-stepzilla";
/* Import Components */
import SiteBasic from "./site containers/SiteBasic";
import SiteContact from "./site containers/SiteContact";
import SiteSpecialty from "./site containers/SiteSpecialty";
import SitePracticeType from "./site containers/SitePracticeType";
import SiteCategories from "./site containers/SiteCategories";
import SiteDetails1 from "./site containers/SiteDetails1";
import SiteDetails2 from "./site containers/SiteDetails2";
import confirmService from "../components/confirmService";
import site_schema from "../content/site_schema";
import "../css/main.css";

class Site extends Component {
  constructor(props) {
    super(props);

    this.state = {
      siteObject: site_schema,
      currentSiteStep: 0,
      site_name: this.props.site_names[this.props.currentSite],
      site_number: this.props.currentSite
    };
    this.getState = this.getState.bind(this);
    this.addSiteStep = this.addSiteStep.bind(this);
    this.removeSiteStep = this.removeSiteStep.bind(this);
    this.steps = [
      {
        name: "Site Basic",
        component: (
          <SiteBasic
            getSiteData={this.props.getSiteData}
            updateSite={this.props.updateSite}
            getData={this.props.getData}
            currentSite={this.props.currentSite}
            key={"Site" + this.props.currentSite}
          />
        )
      },
      {
        name: "Site Contact",
        component: (
          <SiteContact
            getSiteData={this.props.getSiteData}
            updateSite={this.props.updateSite}
            getData={this.props.getData}
            currentSite={this.props.currentSite}
            key={"Site" + this.props.currentSite}
          />
        )
      },
      {
        name: "Site Specialty",
        component: (
          <SiteSpecialty
            getSiteData={this.props.getSiteData}
            updateSite={this.props.updateSite}
            addSiteStep={this.addSiteStep}
            removeSiteStep={this.removeSiteStep}
            getData={this.props.getData}
            currentSite={this.props.currentSite}
            getState={this.getState}
            key={"Site" + this.props.currentSite}
            handleFocusReset={this.props.handleFocusReset}
          />
        )
      },
      {
        name: "Site Practice Type",
        component: (
          <SitePracticeType
            getSiteData={this.props.getSiteData}
            updateSite={this.props.updateSite}
            getData={this.props.getData}
            currentSite={this.props.currentSite}
            key={"Site" + this.props.currentSite}
          />
        )
      },
      {
        name: "Site Categories",
        component: (
          <SiteCategories
            getSiteData={this.props.getSiteData}
            updateSite={this.props.updateSite}
            getData={this.props.getData}
            currentSite={this.props.currentSite}
            key={"Site" + this.props.currentSite}
          />
        )
      },
      {
        name: "Site Details 1",
        component: (
          <SiteDetails1
            getSiteData={this.props.getSiteData}
            updateSite={this.props.updateSite}
            addSite={this.props.addStep}
            getData={this.props.getData}
            currentSite={this.props.currentSite}
            key={"Site" + this.props.currentSite}
          />
        )
      },
      {
        name: "Site Details 2",
        component: (
          <SiteDetails2
            getSiteData={this.props.getSiteData}
            updateSite={this.props.updateSite}
            addSite={this.props.addStep}
            getData={this.props.getData}
            currentSite={this.props.currentSite}
            key={"Site" + this.props.currentSite}
          />
        )
      }
    ];
  }

  getState() {
    return this.state;
  }

  addSiteStep(step) {
    var new_step_name = step.name;
    if (!this.steps.find(step => step.name === new_step_name)) {
      //check if step with given name already exists in steps list
      this.steps.splice(this.state.currentSiteStep + 1, 0, step);
    }
  }

  removeSiteStep(step_name) {
    var index = this.steps.findIndex(step => step.name === step_name);
    if (index > -1) {
      this.steps.splice(index, 1);
    }
  }

  async isValidated() {
    var valid = await this.refs.siteStepper.refs.activeComponent.isValidated();
    if (valid) {
      await this.checkForAnotherSite();
    }
    return valid;
  }

  async checkForAnotherSite() {
    this.props.handleFocusReset();
    if (
      this.state.site_number === this.props.getSiteData().length - 1 &&
      this.state.site_number < 2
    ) {
      const new_site = await confirmService.show({
        message: "Do you practice at another site?",
        confirm_button_text: "Yes",
        cancel_button_text: "No"
      });
      if (new_site) {
        let newSite = this.state.site_number + 1;
        this.props.addSite(
          {
            name: this.props.site_names[newSite],
            component: (
              <Site
                getSiteData={this.props.getSiteData}
                updateSite={this.props.updateSite}
                addSite={this.props.addSite}
                getData={this.props.getData}
                showMainNext={this.props.showMainNext}
                showMainPrev={this.props.showMainPrev}
                currentSite={newSite}
                site_names={this.props.site_names}
                key={"Site " + newSite}
                handleFocusReset={this.props.handleFocusReset}
              />
            )
          },
          newSite
        );
        this.setState(prevState => ({
          ...prevState,
          site_number: this.state.site_number + 1,
          site_name: this.props.site_names[this.site_number + 1]
        }));
      }
    }
    //return true;
  }

  updateSite() {
    this.props.handleSiteUpdate(this.state.siteObject, this.props.site_number);
  }

  render() {
    return (
      <>
        {/* <h1>{this.state.site_name}</h1> */}
        <div className="step-progress">
          <StepZilla
            ref="siteStepper"
            steps={this.steps}
            showSteps={false}
            showNavigation={true}
            stepsNavigation={false}
            preventEnterSubmission={true}
            nextButtonText={"Next"}
            nextButtonCls={"btn btn-warning float-right btn-lg"}
            backButtonCls={
              this.currentSiteStep === 0
                ? "d-none"
                : "btn btn-warning float-left btn-lg"
            }
            nextTextOnFinalActionStep={"Next"}
            onStepChange={step => {
              this.setState(prevState => ({
                ...prevState,
                currentSiteStep: step
              }));
              step >= this.steps.length - 1
                ? this.props.showMainNext(true)
                : this.props.showMainNext(false);
              step === 0
                ? this.props.showMainPrev(true)
                : this.props.showMainPrev(false);
              this.props.handleFocusReset();
            }}
          />
        </div>
      </>
    );
  }
}

export default Site;
