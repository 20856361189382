import React, { Component } from 'react';

/* Import Components */

class End extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.getData().first_name + ' ' + this.props.getData().last_name
    }
  }

  componentDidMount() {
    document.title = 'TPCO Provider Assessment - End';
    document.getElementById("page_title").innerHTML="TPCO Provider Assessment - End";
  }

  render() {
    return (
      <div>{'Thank you ' + this.state.name + ' for taking the time to complete this survey. We truly value the information'
        + ' you have provided. Your responses will contribute to our analyses and the information'
        + ' given will not be circulated and will only be used to evaluate and/or apply for the HPSA designation.'
        + ' For more information, you may go to our webpage at '}
        <a href="https://www.dshs.texas.gov/chpr/default.shtm"> <u>Texas Primary Care Office (TPCO)</u> </a>
        {' or to email us at '}<a href="mailto:TexasPCO@dshs.texas.gov"> <u>TexasPCO@dshs.texas.gov</u> </a>.</div>
    )
  }
}

export default End