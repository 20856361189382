import React, { Component } from "react";

/* Import Components */
import Input from "../../components/Input";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

/* Import Functions */
import {
  handleSiteInput,
  handleBlur,
  blurAll,
  validationCheck,
  grabUserInput,
  validationErrors,
  defaultValidator,
  handleError
} from "../../Utils.js";
import { percentage_regex } from "../../validation_expressions";

class SiteCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        homeless_percentage: this.props.getSiteData()[this.props.currentSite].homeless_percentage,
        native_american_percentage: this.props.getSiteData()[this.props.currentSite]
          .native_american_percentage,
        migrant_farmworker_percentage: this.props.getSiteData()[this.props.currentSite]
          .migrant_farmworker_percentage,
        seasonal_migrant_farmworker_percentage: this.props.getSiteData()[
          this.props.currentSite
        ].seasonal_migrant_farmworker_percentage
      },
      touched: {
        homeless_percentage: false,
        native_american_percentage: false,
        migrant_farmworker_percentage: false,
        seasonal_migrant_farmworker_percentage: false
      },
      errors: {
        homeless_percentage: "",
        native_american_percentage: "",
        migrant_farmworker_percentage: "",
        seasonal_migrant_farmworker_percentage: ""
      }
    };
    this.site_number = this.props.currentSite;
    this.handleInput = handleSiteInput.bind(this);
    this.handleBlur = handleBlur.bind(this);
    this.blurAll = blurAll.bind(this);
    this._grabUserInput = grabUserInput.bind(this);
    this._validationErrors = validationErrors.bind(this);
    this.validationCheck = validationCheck.bind(this);
    this.isValidated = defaultValidator.bind(this, true);
    this.handleError = handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'TPCO Provider Assessment - Site Categories';
    document.getElementById("page_title").innerHTML="TPCO Provider Assessment - Site Categories";
  }

  _validateData(data) {
    return {
      homeless_percentage: percentage_regex.test(data.homeless_percentage), // required: regex w3c uses in html5
      native_american_percentage: percentage_regex.test(data.native_american_percentage),
      migrant_farmworker_percentage: percentage_regex.test(data.migrant_farmworker_percentage),
      seasonal_migrant_farmworker_percentage:
        percentage_regex.test(data.seasonal_migrant_farmworker_percentage)
    };
  }

  render() {
    return (
      <>
        <Container className="pb-4">
          <h2 className="form-label"><span>
            What percentage of your patients are comprised of the following
            special categories at this site:
          </span></h2>
        </Container>
        <Form.Row className={"container mx-auto"}>
          <Form.Group as={Col} xs={12} md={6}>
            <Input
              errorClassName={this.state.errors.homeless_percentage}
              inputType={"number"}
              name={"homeless_percentage"}
              title={"Homeless *"}
              value={this.state.data.homeless_percentage}
              placeholder={"0-100"}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please enter a number between 0 and 100"}
              touched={this.state.touched.homeless_percentage}
              min="0"
              max="100"
            />{" "}
            {/* Homeless */}
          </Form.Group>
          <Form.Group as={Col} xs={12} md={6}>
            <Input
              errorClassName={this.state.errors.native_american_percentage}
              inputType={"number"}
              name={"native_american_percentage"}
              title={"Native American *"}
              value={this.state.data.native_american_percentage}
              placeholder={"0-100"}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please enter a number between 0 and 100"}
              touched={this.state.touched.native_american_percentage}
              min="0"
              max="100"
            />{" "}
            {/* Native American */}
          </Form.Group>
        </Form.Row>
        <Form.Row className={"container mx-auto"}>
          <Form.Group as={Col} xs={12} md={6}>
            <Input
              errorClassName={this.state.errors.migrant_farmworker_percentage}
              inputType={"number"}
              name={"migrant_farmworker_percentage"}
              title={"Migrant Farmworker *"}
              value={this.state.data.migrant_farmworker_percentage}
              placeholder={"0-100"}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please enter a number between 0 and 100s"}
              touched={this.state.touched.migrant_farmworker_percentage}
              min="0"
              max="100"
            />{" "}
            {/* Migrant Farmworker */}
          </Form.Group>
          <Form.Group as={Col} xs={12} md={6}>
            <Input
              errorClassName={this.state.errors.seasonal_migrant_farmworker_percentage}
              inputType={"number"}
              name={"seasonal_migrant_farmworker_percentage"}
              title={"Seasonal Migrant Farmworker *"}
              value={this.state.data.seasonal_migrant_farmworker_percentage}
              placeholder={"0-100"}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please enter a number between 0 and 100"}
              touched={this.state.touched.seasonal_migrant_farmworker_percentage}
              min="0"
              max="100"
            />{" "}
            {/* Seasonal Migrant Farmworker */}
          </Form.Group>
        </Form.Row>
        {"* Items are required"}
      </>
    );
  }
}

export default SiteCategories;
