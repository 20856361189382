const mental_specialties = { 
    "PSY" : [
        { value: "Psychiatry", text: "Psychiatry" },
        { value: "Addiction Medicine", text: "Addiction Medicine" },
        { value: "Addiction Psychiatry", text: "Addiction Psychiatry" },
        { value: "Child & Adolescent Psychiatry", text: "Child & Adolescent Psychiatry"},
        { value: "Geriatric Psychiatry", text: "Geriatric Psychiatry"},
        { value: "Instructor/Faculty", text: "Instructor/Faculty"},
        { value: "Hospitalist", text: "Hospitalist" },
        { value: "Other", text: "Other" }
    ],
    "CPSY" : [
        { value: "Psychologist", text: "Psychologist" },
        { value: "Addiction", text: "Addiction" },
        { value: "Adult Development & Aging", text: "Adult Development & Aging" },
        { value: "Cognitive & Behavioral", text: "Cognitive & Behavioral" },
        { value: "Clinical", text: "Clinical" },
        { value: "Counseling", text: "Counseling" },
        { value: "Clinical Child & Adolescent", text: "Clinical Child & Adolescent" },
        { value: "Educational", text: "Educational" },
        { value: "Family", text: "Family" },
        { value: "Health", text: "Health" },
        { value: "Health Service", text: "Health Service" },
        { value: "Prescribing (Medical)", text: "Prescribing (Medical)" },
        { value: "Psychoanalysis", text: "Psychoanalysis"},
        { value: "Psychotherapy", text: "Psychotherapy" },
        { value: "School", text: "School" },
        { value: "Instructor/Faculty", text: "Instructor/Faculty"},
        { value: "Hospitalist", text: "Hospitalist" },
        { value: "Other", text: "Other" }
    ],
    "CSW" : [
        { value: "School", text: "School" },
        { value: "Clinical", text: "Clinical" },
        { value: "Social Worker", text: "Social Worker" },
        { value: "Instructor/Faculty", text: "Instructor/Faculty"},
        { value: "Hospitalist", text: "Hospitalist" },
        { value: "Other", text: "Other" }
    ],
    "MFT" : [
        { value: "Marriage & Family Therapy", text: "Marriage & Family Therapy" },
        { value: "Instructor/Faculty", text: "Instructor/Faculty"},
        { value: "Hospitalist", text: "Hospitalist" },
        { value: "Other", text: "Other" }
    ],
    "PNS" : [
        { value: "NP-Psych/Mental Health", text: "NP-Psych/Mental Health" },
        { value: "CNS–Psych/Mental Health-Child & Adolescent", text: "CNS–Psych/Mental Health-Child & Adolescent" },
        { value: "CNS-Psych/Mental Health-Adult", text: "CNS-Psych/Mental Health-Adult" },
        { value: "CNS-Psych/Mental Health", text: "CNS-Psych/Mental Health" },
        { value: "Instructor/Faculty", text: "Instructor/Faculty"},
        { value: "Hospitalist", text: "Hospitalist" },
        { value: "Other", text: "Other" }
    ]
}
export default mental_specialties