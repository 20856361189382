import React from "react";

const Input = (props) => (
  <div className="form-group">
    <label htmlFor={props.name} className="form-label">{props.title}</label>
    <input
      tabIndex="2"
      className={"form-control " + props.errorClassName + " " + props.className}
      id={props.name}
      ref={props.ref}
      name={props.name}
      type={props.inputType}
      value={props.value || props.value === 0 ? props.value : ""}
      onChange={props.handleChange}
      onBlur={props.onBlur}
      placeholder={props.placeholder}
      min={props.min}
      max={props.max}
    />
    {props.error.length > 0 && props.touched &&
    <div className={"invalid-feedback"} role="alert">
      {props.error}
    </div>}
  </div>
)

export default Input;