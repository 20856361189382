import React, { Component } from "react";
/* Import Components */
import CheckBox from "../components/CheckBox";
import special_categories_options from "../content/option_lists/special_categories_options";
/* Import Functions */
import {
  handleInput,
  handleBlur,
  blurAll,
  validationCheck,
  grabUserInput,
  validationErrors,
  defaultValidator,
  checkEmpty
} from "../Utils.js";

class SpecialCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        special_categories: this.props.getData().special_categories
      },
      touched: {
        special_categories: false
      },
      errors: {
        special_categories: ""
      }
    };

    this.handleInput = handleInput.bind(this);
    this.handleBlur = handleBlur.bind(this);
    this.blurAll = blurAll.bind(this);
    this._grabUserInput = grabUserInput.bind(this);
    this._validationErrors = validationErrors.bind(this);
    this.validationCheck = validationCheck.bind(this);
    this.defaultValidator = defaultValidator.bind(this);
    this.isValidated = this.isValidated.bind(this);
  }

  componentDidMount() {
    document.title = 'TPCO Provider Assessment - Special Categories';
    document.getElementById("page_title").innerHTML = "TPCO Provider Assessment - Special Categories";
  }

  _validateData(data) {
    return {
      special_categories: true // required: regex w3c uses in html5
    };
  }

  async isValidated() {
    let special_categories = await this._grabUserInput().special_categories;
    this.props.handleFocusReset();
    return this.defaultValidator(false, async function () {
      let confirmed = await checkEmpty(special_categories,
        "No special categories apply?");
      return confirmed;
    });
  }

  render() {
    return (
      <>
        <CheckBox
          title={"Please select any of the following categories that apply:"}
          name={"special_categories"}
          options={special_categories_options}
          selectedOptions={this.state.data.special_categories}
          handleChange={this.handleInput}
        />{" "}
        {/* Special Categories */}
      </>
    );
  }
}

export default SpecialCategories;
