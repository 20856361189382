import React from "react";
import Modal from "react-bootstrap/Modal";
import { FadeLoader } from "react-spinners";
import { css } from "@emotion/core";
import { usePromiseTracker } from "react-promise-tracker";

const override = css`
  display: block;
  margin: 0 auto;
`;

export const LoadingSpinner = props => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    <>
      {promiseInProgress && (
        <Modal show={true} backdrop={"static"} keyboard={false}>
          <Modal.Body>
            <div className="mx-auto">
              <FadeLoader css={override} loading={true} />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
