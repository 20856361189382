import React, { Component } from "react";

/* Import Components */
import Input from "../../components/Input";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
/* Import Functions */
import {
  handleSiteInput,
  handleBlur,
  blurAll,
  validationCheck,
  grabUserInput,
  validationErrors,
  defaultValidator,
  handleError
} from "../../Utils.js";
import { email_regex, phone_regex } from "../../validation_expressions";

class SiteContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        email: this.props.getSiteData()[this.props.currentSite].email,
        phone: this.props.getSiteData()[this.props.currentSite].phone,
        fax: this.props.getSiteData()[this.props.currentSite].fax
      },
      touched: {
        email: false,
        phone: false,
        fax: false
      },
      errors: {
        email: "",
        phone: "",
        fax: ""
      }
    };
    this.site_number = this.props.currentSite;
    this.formatPhone = this.formatPhone.bind(this);
    this.handleInput = handleSiteInput.bind(this);
    this.handleBlur = handleBlur.bind(this);
    this.blurAll = blurAll.bind(this);
    this._grabUserInput = grabUserInput.bind(this);
    this._validationErrors = validationErrors.bind(this);
    this.validationCheck = validationCheck.bind(this);
    this.isValidated = defaultValidator.bind(this, true);
    this.handleError = handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'TPCO Provider Assessment - Site Contact';
    document.getElementById("page_title").innerHTML="TPCO Provider Assessment - Site Contact";
  }
  
  _validateData(data) {
    return {
      email: email_regex.test(data.email), // required: regex w3c uses in html5
      phone: phone_regex.test(data.phone),
      fax: data.fax === "" || phone_regex.test(data.fax)
    };
  }

  formatPhone(e) {
    const target = e.target;
    const input = e.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      target.value = `(${zip}) ${middle}-${last}`;
    } else if (input.length > 3) {
      target.value = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      target.value = `(${zip}`;
    } else {
      target.value = input;
    }
    this.handleInput(e);
  }

  render() {
    return (
      <>
        <Container>
          <Input
            errorClassName={this.state.errors.email}
            inputType={"text"}
            name={"email"}
            title={"Site Email *"}
            value={this.state.data.email}
            placeholder={"example@domain.com"}
            handleChange={this.handleInput}
            onBlur={this.handleBlur}
            error={"Please enter the email address of this site"}
            touched={this.state.touched.email}
          />{" "}
          {/* Email */}
        </Container>
        <Form.Row className={"container mx-auto"}>
          <Form.Group as={Col} xs={12} md={6}>
            <Input
              errorClassName={this.state.errors.phone}
              inputType={"tel"}
              name={"phone"}
              title={"Phone Number *"}
              value={this.state.data.phone}
              placeholder={"(555) 555-5555"}
              handleChange={this.formatPhone}
              onBlur={this.handleBlur}
              error={"Please enter the phone number for this site"}
              touched={this.state.touched.phone}
            />{" "}
            {/* Phone Number */}
          </Form.Group>
          <Form.Group as={Col} xs={12} md={6}>
            <Input
              errorClassName={this.state.errors.fax}
              inputType={"tel"}
              name={"fax"}
              title={"Fax Number"}
              value={this.state.data.fax}
              placeholder={"(555) 555-5555"}
              handleChange={this.formatPhone}
              onBlur={this.handleBlur}
              error={"Please enter the fax number for this site"}
              touched={this.state.touched.fax}
            />{" "}
            {/* Fax Number */}
          </Form.Group>
        </Form.Row>
        {"* Items are required"}
      </>
    );
  }
}

export default SiteContact;
