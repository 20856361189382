import React from "react";

const Select = props => (
  <div className="form-group">
    <label htmlFor={props.name} className="form-label"> {props.title} </label>
    <select
      tabIndex="2"
      className={"form-control " + props.errorClassName}
      id={props.name}
      name={props.name}
      value={props.value}
      onChange={props.handleChange}
      onBlur={props.onBlur}
    >
      <option value="" disabled>
        {props.placeholder}
      </option>
      {props.options.map(option => (
        <option
          key={option.value || option}
          value={option.value || option}
          label={option.text ? option.text : option.value || option}
        >
          {option.text ? option.text : option.value || option}
        </option>
      ))}
    </select>
    {props.error.length > 0 && props.touched &&
      <div className={"invalid-feedback"} role="alert">
        {props.error}
      </div>}
  </div>
);

export default Select;
