import React, { Component } from "react";
/* Import Components */
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import TextArea from "../components/TextArea";
import Input from "../components/Input";
import Select from "../components/Select";
import submitter_titles from "../content/option_lists/submitter_titles";
import {
  handleInput,
  handleBlur,
  blurAll,
  validationCheck,
  grabUserInput,
  validationErrors,
  defaultValidator
} from "../Utils.js";
import { name_regex } from "../validation_expressions";

class FinalQuestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        submitter_name: this.props.getData().submitter_name,
        submitter_title: this.props.getData().submitter_title,
        comments: this.props.getData().comments
      },
      touched: {
        submitter_name: false,
        submitter_title: false
      },
      errors: {
        submitter_name: "",
        submitter_title: ""
      }
    };

    this.handleInput = handleInput.bind(this);
    this.handleBlur = handleBlur.bind(this);
    this.blurAll = blurAll.bind(this);
    this._grabUserInput = grabUserInput.bind(this);
    this._validationErrors = validationErrors.bind(this);
    this.validationCheck = validationCheck.bind(this);
    this.defaultValidator = defaultValidator.bind(this);
    this.isValidated = this.isValidated.bind(this);
  }

  componentDidMount() {
    document.title = 'TPCO Provider Assessment - Final Questions';
    document.getElementById("page_title").innerHTML="TPCO Provider Assessment - Final Questions";
  }

  _validateData(data) {
    return {
      submitter_name:
        name_regex.test(data.submitter_name) &&
        data.submitter_name.length > 0, // required: anything besides N/A
      submitter_title: data.submitter_title !== "" // required: regex w3c uses in html5
    };
  }

  isValidated() {
    return this.defaultValidator(false, this.props.submit);
  }

  render() {
    return (
      <>
        <Container>
          <TextArea
            title={"Do you have any comments, questions, or concerns?"}
            rows={5}
            value={this.state.data.comments}
            name={"comments"}
            inputType={"text"}
            handleChange={this.handleInput}
            onBlur={this.handleBlur}
            placeholder={"Comments, Questions, Concerns"}
          />
          {/* Comments */}
        </Container>
        <label className="form-label">Assessment completed by:</label>
        <Form.Row className={"container mx-auto"}>
          <Form.Group as={Col} xs={12} md={6}>
            <Input
              errorClassName={this.state.errors.submitter_name}
              inputType={"text"}
              name={"submitter_name"}
              title={"Name *"}
              value={this.state.data.submitter_name}
              placeholder={"Enter your full name"}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please enter your name"}
              touched={this.state.touched.submitter_name}
            />{" "}
            {/* Submitter Name */}
          </Form.Group>
          <Form.Group as={Col} xs={12} md={6}>
            <Select
              errorClassName={this.state.errors.submitter_title}
              name={"submitter_title"}
              title={"Title or Position *"}
              value={this.state.data.submitter_title}
              placeholder={"Select your title or position"}
              options={submitter_titles}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please select your title or position"}
              touched={this.state.touched.submitter_title}
            />{" "}
            {/* Submitter Title*/}
          </Form.Group>
        </Form.Row>
        {"* Items are required"}
      </>
    );
  }
}

export default FinalQuestions;
