import React, { Component } from "react";

/* Import Components */
import Radio from "../../components/Radio";
import Input from "../../components/Input";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

/* Import Functions */
import {
  handleSiteInput,
  handleBlur,
  blurAll,
  validationCheck,
  grabUserInput,
  validationErrors,
  defaultValidator,
  handleError,
  getSpecialties
} from "../../Utils.js";
 import {percentage_regex} from "../../validation_expressions.js"

class SiteSubSpecialty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        sub_specialty: this.props.getSiteData()[this.props.currentSite]
          .sub_specialty,
        sub_specialty_other: this.props.getSiteData()[this.props.currentSite]
          .sub_specialty_other,
        sub_specialty_other_percentage: this.props.getSiteData()[
          this.props.currentSite
        ].sub_specialty_other_percentage
      },
      touched: {
        sub_specialty: false,
        sub_specialty_other: false,
        sub_specialty_other_percentage: false
      },
      errors: {
        sub_specialty: "",
        sub_specialty_other: "",
        sub_specialty_other_percentage: ""
      }
    };

    this.site_number = this.props.currentSite;
    this.getSpecialties = getSpecialties.bind(this);
    this.handleInput = handleSiteInput.bind(this);
    this.handleBlur = handleBlur.bind(this);
    this.blurAll = blurAll.bind(this);
    this._grabUserInput = grabUserInput.bind(this);
    this._validationErrors = validationErrors.bind(this);
    this.validationCheck = validationCheck.bind(this);
    this.isValidated = defaultValidator.bind(this, true);
    this.handleError = handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'TPCO Provider Assessment - Site Sub Specialty';
    document.getElementById("page_title").innerHTML="TPCO Provider Assessment - Site Sub Specialty";
  }
  
  _validateData(data) {
    return {
      sub_specialty: data.sub_specialty.length > 0,
      sub_specialty_other:
        data.sub_specialty !== "Other" || data.sub_specialty_other !== "",
      sub_specialty_other_percentage:
        data.sub_specialty !== "Other" || (percentage_regex.test(data.sub_specialty_other_percentage) && data.sub_specialty_other_percentage !== 0)
    };
  }

  render() {
    return (
      <>
        <Radio
          errorClassName={this.state.errors.sub_specialty}
          title={"Site Sub-specialty *"}
          name={"sub_specialty"}
          options={this.getSpecialties()}
          value={this.state.data.sub_specialty}
          handleChange={this.handleInput}
          onBlur={this.handleBlur}
          error={"Please select the sub-specialty at this site"}
          touched={this.state.touched.sub_specialty}
        />{" "}
        {/* Specialty */}
        {this.state.data.sub_specialty.includes("Other") && (
          <Form.Row className={"container mx-auto"}>
            <Form.Group as={Col} xs={12} sm={6}>
              <Input
                errorClassName={this.state.errors.sub_specialty_other}
                inputType={"text"}
                name={"sub_specialty_other"}
                title={"Please specify other specialty *"}
                value={this.state.data.sub_specialty_other}
                placeholder={"Specify other"}
                handleChange={this.handleInput}
                onBlur={this.handleBlur}
                error={"Please specify other specialty"}
                touched={this.state.touched.sub_specialty_other}
              />{" "}
              {/* Other Sub-Specialty */}
            </Form.Group>
            <Form.Group>
              <Input
                errorClassName={
                  this.state.errors.sub_specialty_other_percentage
                }
                inputType={"number"}
                name={"sub_specialty_other_percentage"}
                title={
                  "What percentage of your time is spent practicing this specialty? *"
                }
                value={this.state.data.sub_specialty_other_percentage}
                placeholder={"0-100"}
                handleChange={this.handleInput}
                onBlur={this.handleBlur}
                error={"Please enter a number between 1 and 100"}
                touched={this.state.touched.sub_specialty_other_percentage}
                min="0"
                max="100"
              />{" "}
              {/* Other Sub-Specialty Percentage */}
            </Form.Group>
          </Form.Row>
        )}
        {"* Items are required"}
      </>
    );
  }
}

export default SiteSubSpecialty;
