import React, { Component } from "react";
//import StepZilla from "react-stepzilla";
import StepZilla from "../custom_node_modules/react-stepzilla";
/* Import Components */
import End from "../containers/End";
import Start from "../containers/Start";
import BasicInfo from "../containers/BasicInfo";
import Site from "../containers/Site";
import SpecialCategories from "../containers/SpecialCategories";
import StatusChange from "../containers/StatusChange";
import FinalQuestions from "../containers/FinalQuestions";
import { LoadingSpinner } from "../components/LoadingSpinner";
/*Schemas*/
import provider_schema from "../content/provider_schema";
import site_schema from "../content/site_schema";
import { trackPromise } from "react-promise-tracker";
/*CSS*/
import "../css/main.css";

class FormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      currentSite: 0,
      showNext: true,
      showPrev: true,
      showSteps: false,
      site_names: [
        "Primary Practice Location",
        "Secondary Practice Location",
        "Third Practice Location"
      ]
    };
    this.provider = provider_schema;
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSiteUpdate = this.handleSiteUpdate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getData = this.getData.bind(this);
    this.getSiteData = this.getSiteData.bind(this);
    this.addStep = this.addStep.bind(this);
    this.removeStep = this.removeStep.bind(this);
    this.getLastStepIndex = this.getLastStepIndex.bind(this);
    this.setShowNext = this.setShowNext.bind(this);
    this.setShowPrev = this.setShowPrev.bind(this);
    this.ParseArrays = this.ParseArrays.bind(this);
    this.ConvertBools = this.ConvertBools.bind(this);
    this.steps = [
      {
        name: "Confirm NPI",
        component: (
          <Start getData={this.getData} updateProvider={this.handleUpdate} />
        )
      },
      {
        name: "Basic Info",
        component: (
          <BasicInfo
            getData={this.getData}
            updateProvider={this.handleUpdate}
            addStep={this.addStep}
            removeStep={this.removeStep}
            getLastStepIndex={this.getLastStepIndex}
          />
        )
      },
      {
        name: "Special Categories",
        component: (
          <SpecialCategories
            getData={this.getData}
            updateProvider={this.handleUpdate}
            handleFocusReset={this.handleFocusReset}
          />
        )
      },
      {
        name: "Status Change",
        component: (
          <StatusChange
            getData={this.getData}
            updateProvider={this.handleUpdate}
            handleFocusReset={this.handleFocusReset}
          />
        )
      },
      {
        name: "Primary Practice Location",
        component: (
          <Site
            getSiteData={this.getSiteData}
            updateSite={this.handleSiteUpdate}
            addSite={this.addStep}
            getData={this.getData}
            showMainNext={this.setShowNext}
            showMainPrev={this.setShowPrev}
            currentSite={0}
            key={"Site 0"}
            site_names={this.state.site_names}
            handleFocusReset={this.handleFocusReset}
          />
        )
      },
      {
        name: "Final Questions",
        component: (
          <FinalQuestions
            getData={this.getData}
            updateProvider={this.handleUpdate}
            submit={this.handleSubmit}
          />
        )
      },
      { name: "End", component: <End getData={this.getData} /> }
    ];
  }

  getLastStepIndex() {
    if (this.steps) {
      return this.steps.length - 1;
    } else {
      return 0;
    }
  }

  addStep(step, newSite) {
    var new_step_name = step.name;
    if (!this.steps.find(step => step.name === new_step_name)) {
      //check if step with given name already exists in steps list
      if (newSite) {
        this.setState(prevState => ({
          ...prevState,
          currentSite: newSite
        }));
        this.provider.sites.push(site_schema);
      }
      this.steps.splice(this.state.currentStep + 1, 0, step);
    }
  }

  removeStep(step_name) {
    var index = this.steps.findIndex(step => step.name === step_name);
    if (index > -1) {
      this.steps.splice(index, 1);
    }
  }

  getData() {
    return this.provider;
  }

  getSiteData() {
    return this.provider.sites;
  }

  handleUpdate(object) {
    try {
      Object.keys(object).map(
        key =>
          (this.provider = {
            ...this.provider,
            [key]: this.trimStrings(object[key])
          })
      );
    } catch (error) {
      throw error;
    }
  }

  handleSiteUpdate(object, site_number) {
    try {
      Object.keys(object).map(
        key =>
          (this.provider.sites[site_number] = {
            ...this.provider.sites[site_number],
            [key]: this.trimStrings(object[key])
          })
      );
    } catch (error) {
      throw error;
    }
  }

  trimStrings(potential_string) {
    return potential_string !== null && potential_string.typeof === "string"
      ? potential_string.trim()
      : potential_string;
  }

  async handleSubmit() {
    let provider = this.provider;
    let data = this.ParseArrays(provider);
    this.ConvertBools(data);
    let response = await trackPromise(this.postData(JSON.stringify(data)));
  }

  ParseArrays(object) {
    let new_object = {};
    Object.keys(object).map(element => {
      if (
        Array.isArray(object[element]) &&
        !object[element].some(value => {
          return typeof value === "object";
        })
      ) {
        object[element].map(x =>
          typeof x === "string" && x.toLowerCase() !== "other"
            ? (new_object[x] = true)
            : ""
        );
      } else {
        new_object[element] = object[element];
      }
      return new_object;
    });
    return new_object;
  }

  ConvertBools(object) {
    Object.keys(object).forEach(element => {
      if (object[element] != null && typeof object[element] === "object") {
        this.ConvertBools(object[element]);
      } else {
        if (
          typeof object[element] === "string" &&
          ["true", "false"].includes(object[element].toLowerCase())
        ) {
          object[element] = object[element] === "true";
        }
      }
    });
  }

  async postData(data) {
    let result;
    await fetch(
      process.env.REACT_APP_API_URL,

      {
        method: "post",
        body: data,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          "Accept-Encoding": "gzip/deflate"
        }
      }
    ).then(function (response) {
      if (response.ok) {
        result = response.json();
      } else {
        throw Error(response.statusText);
      }
    });
    return result;
  }

  setShowNext(show) {
    this.setState({
      showNext: show
    });
    this.handleFocusReset();
  }
  setShowPrev(show) {
    this.setState({
      showPrev: show
    });
    this.handleFocusReset();
  }

  handleFocusReset = () => {
    this.props.resetFocus();
  }

  render() {
    const showPrev = this.state.showPrev;
    const showNext = this.state.showNext;
    return (
      <form
        className="container col-md-10"
        onSubmit={this.handleFormSubmit}
      >
        <div className="step-progress">
          <StepZilla
            steps={this.steps}
            showSteps={
              this.state.currentStep === 0 ||
                this.state.currentStep === this.steps.length - 1
                ? false
                : true
            }
            showNavigation={true}
            stepsNavigation={true}
            nEnterSubmission={true}
            backButtonCls={
              showPrev ? "btn btn-warning float-left btn-lg" : "d-none"
            }
            nextButtonText={"Next"}
            nextButtonCls={
              showNext ? "btn btn-warning float-right btn-lg" : "d-none"
            }
            nextTextOnFinalActionStep={"Submit"}
            onStepChange={step => {
              this.setState(
                prevState => ({
                  ...prevState,
                  currentStep: step
                }),
                this.setShowNext(
                  this.state.site_names.includes(this.steps[step].name)
                    ? false
                    : true
                ),
                this.setShowPrev(
                  step === 0 || step >= this.getLastStepIndex() ? false : true
                )
              );
            }}
          />
        </div>
        <LoadingSpinner />
      </form>
    );
  }
}

export default FormContainer;
