import React, { Component } from "react";

/* Import Components */
import Radio from "../../components/Radio";
import Input from "../../components/Input";
/* Import option arrays */
import practice_types from "../../content/option_lists/practice_types";
/* Import Functions */
import {
  handleSiteInput,
  handleBlur,
  blurAll,
  validationCheck,
  grabUserInput,
  validationErrors,
  defaultValidator,
  handleError
} from "../../Utils.js";

class SitePracticeType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        practice_type: this.props.getSiteData()[this.props.currentSite]
          .practice_type,
        practice_type_other: this.props.getSiteData()[this.props.currentSite]
          .practice_type_other,
        new_patients:  this.props.getSiteData()[this.props.currentSite]
        .new_patients
      },
      touched: {
        practice_type: false,
        practice_type_other: false,
        new_patients: false
      },
      errors: {
        practice_type: "",
        practice_type_other: "",
        new_patients: false
      }
    };
    this.site_number = this.props.currentSite;
    this.handleInput = handleSiteInput.bind(this);
    this.handleBlur = handleBlur.bind(this);
    this.blurAll = blurAll.bind(this);
    this._grabUserInput = grabUserInput.bind(this);
    this._validationErrors = validationErrors.bind(this);
    this.validationCheck = validationCheck.bind(this);
    this.isValidated = defaultValidator.bind(this, true);
    this.handleError = handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'TPCO Provider Assessment - Site Practice Type';
    document.getElementById("page_title").innerHTML="TPCO Provider Assessment - Site Practice Type";
  }

  _validateData(data) {
    return {
      practice_type: data.practice_type !== "", // required: anything besides N/A
      practice_type_other:
        data.practice_type !== "Other" || data.practice_type_other !== "",
      new_patients: data.new_patients !== null
    };
  }

  render() {
    const new_patients_options = [
      { value: true, text: "Yes" },
      { value: false, text: "No" }
    ];

    return (
      <>
        <Radio
          errorClassName={this.state.errors.practice_type}
          title={"Practice Type *"}
          name={"practice_type"}
          options={practice_types}
          value={this.state.data.practice_type}
          handleChange={this.handleInput}
          onBlur={this.handleBlur}
          error={"Please select the practice type of this site"}
          touched={this.state.touched.practice_type}
        />{" "}
        {/* Practice Type */}
        {this.state.data.practice_type.includes("Other") && (
          <Input
            errorClassName={this.state.errors.practice_type_other}
            inputType={"text"}
            name={"practice_type_other"}
            title={"Please specify other practice type *"}
            value={this.state.data.practice_type_other}
            placeholder={"Specify other"}
            handleChange={this.handleInput}
            onBlur={this.handleBlur}
            error={"Please specify other practice type"}
            touched={this.state.touched.practice_type_other}
          />
        )}{" "}
        {/* Practice Type Other */}
        <Radio
          errorClassName={this.state.errors.new_patients}
          title={"Are you accepting new patients at this site? *"}
          name={"new_patients"}
          options={new_patients_options}
          value={this.state.data.new_patients}
          handleChange={this.handleInput}
          onBlur={this.handleBlur}
          error={"Please select yes or no"}
          touched={this.state.touched.new_patients}
        />{" "}
        {/* New Patients */}
        {"* Items are required"}
      </>
    );
  }
}

export default SitePracticeType;
