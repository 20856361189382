const classification = {
  "PC": [
    { value: "FP", text: "Family Medicine" },
    { value: "IM", text: "Internal Medicine" },
    { value: "OBG", text: "Obstetrics & Gynecology" },
    { value: "PD", text: "Pediatrics" },
    { value: "GP", text: "General Practice"}
  ],
  "MH": [
    { value: "CPSY", text: "Psychologist" },
    { value: "PSY", text: "Psychiatrist" },
    { value: "CSW", text: "Social Worker" },
    { value: "MFT", text: "Marriage Family Therapist" },
    { value: "PNS", text: "Nurse Practitioner/Clinical Nurse Specialist" }
  ],
  "DH": [
    { value: "GDT", text: "Dentist" }  ]
};

export default classification;
