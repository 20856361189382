import React, { Component } from "react";
/* Import Components */
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Radio from "../../components/Radio";
import Input from "../../components/Input";
import confirmService from "../../components/confirmService";

/* Import Functions */
import {
  handleSiteInput,
  handleBlur,
  blurAll,
  validationCheck,
  grabUserInput,
  validationErrors,
  defaultValidator,
  handleSubSpecialty,
  getSpecialties
} from "../../Utils.js";
import { percentage_regex } from "../../validation_expressions";

class SiteSpecialty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        specialty: this.props.getSiteData()[this.props.currentSite].specialty,
        specialty_other: this.props.getSiteData()[this.props.currentSite]
          .specialty_other,
        specialty_other_percentage: this.props.getSiteData()[
          this.props.currentSite
        ].specialty_other_percentage
      },
      touched: {
        specialty: false,
        specialty_other: false,
        specialty_other_percentage: false
      },
      errors: {
        specialty: "",
        specialty_other: "",
        specialty_other_percentage: ""
      }
    };
    //Specific functions
    this.site_number = this.props.currentSite;
    this.isValidated = this.isValidated.bind(this);

    //Utils.js functions
    this.handleInput = handleSiteInput.bind(this);
    this.handleBlur = handleBlur.bind(this);
    this.blurAll = blurAll.bind(this);
    this._grabUserInput = grabUserInput.bind(this);
    this._validationErrors = validationErrors.bind(this);
    this.validationCheck = validationCheck.bind(this);
    this.defaultValidator = defaultValidator.bind(this, true);
    this.getSpecialties = getSpecialties.bind(this);
    this.handleSubSpecialty = handleSubSpecialty.bind(this);
  }

  componentDidMount() {
    document.title = 'TPCO Provider Assessment - Site Specialty';
    document.getElementById("page_title").innerHTML="TPCO Provider Assessment - Site Specialty";
  }

  _validateData(data) {
    return {
      specialty: data.specialty.length > 0,
      specialty_other: data.specialty !== "Other" || data.specialty_other !== "",
      specialty_other_percentage:
        data.specialty !== "Other" || (percentage_regex.test(data.specialty_other_percentage) && data.specialty_other_percentage !== 0)
    };
  }

  async isValidated() {
    let valid = await this.defaultValidator();
    this.props.handleFocusReset();
    if (valid) {
      let add_sub_specialty = await confirmService.show({
        message: "Does this site have a sub-specialty?",
        confirm_button_text: "Yes",
        cancel_button_text: "No"
      });
      let response = await this.handleSubSpecialty(add_sub_specialty);
      return response;
    } else {
      return valid;
    }
  }

  render() {
    return (
      <>
        <Radio
          errorClassName={this.state.errors.specialty}
          title={"Site Specialty *"}
          name={"specialty"}
          options={this.getSpecialties()}
          value={this.state.data.specialty}
          handleChange={this.handleInput}
          onBlur={this.handleBlur}
          error={"Please select the specialty at this site"}
          touched={this.state.touched.specialty}
        />{" "}
        {/* Specialty */}
        {this.state.data.specialty.includes("Other") && (
          <Form.Row className={"container mx-auto"}>
            <Form.Group as={Col} xs={12} sm={6}>
              <Input
                errorClassName={this.state.errors.specialty_other}
                inputType={"text"}
                name={"specialty_other"}
                title={"Please specify other specialty *"}
                value={this.state.data.specialty_other}
                placeholder={"Specify other"}
                handleChange={this.handleInput}
                onBlur={this.handleBlur}
                error={"Please specify other specialty"}
                touched={this.state.touched.specialty_other}
              />{" "}
              {/* Other Specialty */}
            </Form.Group>
            <Form.Group>
              <Input
                errorClassName={this.state.errors.specialty_other_percentage}
                inputType={"number"}
                name={"specialty_other_percentage"}
                title={
                  "What percentage of your time is spent practicing this specialty? *"
                }
                value={this.state.data.specialty_other_percentage}
                placeholder={"0-100"}
                handleChange={this.handleInput}
                onBlur={this.handleBlur}
                error={"Please enter a number between 1 and 100s"}
                touched={this.state.touched.specialty_other_percentage}
                min="0"
                max="100"
              />{" "}
              {/* Other Specialty Percentage */}
            </Form.Group>
          </Form.Row>
        )}
        {"* Items are required"}
      </>
    );
  }
}

export default SiteSpecialty;
