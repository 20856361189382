import React, { Component } from "react";

/* Import Components */
import Input from "../../components/Input";
import Select from "../../components/Select";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
/* Import option arrays */
import counties from "../../content/option_lists/counties";
/* Import Functions */
import {
  handleSiteInput,
  handleBlur,
  blurAll,
  validationCheck,
  grabUserInput,
  validationErrors,
  defaultValidator,
  handleError
} from "../../Utils.js";
import { name_regex, street_address_regex, zip_regex } from "../../validation_expressions";

class SiteBasic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        address: this.props.getSiteData()[this.props.currentSite].address,
        city: this.props.getSiteData()[this.props.currentSite].city,
        state: this.props.getSiteData()[this.props.currentSite].state,
        zip: this.props.getSiteData()[this.props.currentSite].zip,
        county: this.props.getSiteData()[this.props.currentSite].county
      },
      touched: {
        address: false,
        city: false,
        state: false,
        zip: false,
        county: false
      },
      errors: {
        address: "",
        city: "",
        state: "",
        zip: "",
        county: ""
      }
    };
    this.site_number = this.props.currentSite;
    this.handleInput = handleSiteInput.bind(this);
    this.handleBlur = handleBlur.bind(this);
    this.blurAll = blurAll.bind(this);
    this._grabUserInput = grabUserInput.bind(this);
    this._validationErrors = validationErrors.bind(this);
    this.validationCheck = validationCheck.bind(this);
    this.isValidated = defaultValidator.bind(this, true);
    this.handleError = handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'TPCO Provider Assessment - Site Basic';
    document.getElementById("page_title").innerHTML="TPCO Provider Assessment - Site Basic";
  }
  _validateData(data) {
    return {
      address:
       street_address_regex.test(data.address) && data.address.length > 2, // required: regex w3c uses in html5
      city: name_regex.test(data.city) && data.city.length > 2,
      state: data.state.length > 0,
      zip: zip_regex.test(data.zip),
      county: data.county.length > 0
    };
  }
  
  render() {
    const states = Object.keys(counties);
    return (
      <>
        <Container>
          <Input
            errorClassName={this.state.errors.address}
            inputType={"text"}
            name={"address"}
            title={"Site Address *"}
            value={this.state.data.address}
            placeholder={"Address"}
            handleChange={this.handleInput}
            onBlur={this.handleBlur}
            error={"Please enter the address of this site"}
            touched={this.state.touched.address}
          />{" "}
          {/* Address */}
        </Container>
        <Form.Row className={"container mx-auto"}>
          <Form.Group as={Col} xs={12} md={6}>
            <Input
              errorClassName={this.state.errors.city}
              inputType={"text"}
              name={"city"}
              title={"City *"}
              value={this.state.data.city}
              placeholder={"City"}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please enter the city for this site"}
              touched={this.state.touched.city}
            />{" "}
            {/* City */}
          </Form.Group>
          <Form.Group as={Col} xs={12} md={6}>
            <Input
              errorClassName={this.state.errors.zip}
              inputType={"text"}
              name={"zip"}
              title={"Zip Code *"}
              value={this.state.data.zip}
              placeholder={"Zip Code"}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please enter the zip code for this site"}
              touched={this.state.touched.zip}
            />{" "}
            {/* Zip Code */}
          </Form.Group>
        </Form.Row>
        <Form.Row className={"container mx-auto"}>
          <Form.Group as={Col} xs={12} md={6}>
            <Select
              errorClassName={this.state.errors.state}
              title={"State *"}
              name={"state"}
              placeholder={"State"}
              value={this.state.data.state}
              options={states}
              handleChange={this.handleInput}
              onBlur={this.handleBlur}
              error={"Please select the state for this site"}
              touched={this.state.touched.state}
            />{" "}
            {/* State */}
          </Form.Group>
          {this.state.data.state === "" || (
            <Form.Group as={Col} xs={12} md={6}>
              <Select
                errorClassName={this.state.errors.county}
                title={"County *"}
                name={"county"}
                placeholder={"County"}
                value={this.state.data.county}
                options={counties[this.state.data.state]}
                handleChange={this.handleInput}
                onBlur={this.handleBlur}
                error={"Please select the county for this site"}
                touched={this.state.touched.county}
              />{" "}
              {/* County */}
            </Form.Group>
          )}
        </Form.Row>
        {"* Items are required"}
      </>
    );
  }
}

export default SiteBasic;
