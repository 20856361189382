import React, { Component } from "react";
/* Import Components */
import CheckBox from "../components/CheckBox";
import Input from "../components/Input";
import status_change_options from "../content/option_lists/status_change_options";
/* Import Functions */
import {
  handleInput,
  handleBlur,
  blurAll,
  validationCheck,
  grabUserInput,
  validationErrors,
  defaultValidator,
  checkEmpty
} from "../Utils.js";

class StatusChange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        status_changes: this.props.getData().status_changes,
        status_changes_other: this.props.getData().status_changes_other
      },
      touched: {
        status_changes: "",
        status_changes_other: ""
      },
      errors: {
        status_changes: "",
        status_changes_other: ""
      }
    };
    this.handleInput = handleInput.bind(this);
    this.handleBlur = handleBlur.bind(this);
    this.blurAll = blurAll.bind(this);
    this._grabUserInput = grabUserInput.bind(this);
    this._validationErrors = validationErrors.bind(this);
    this.validationCheck = validationCheck.bind(this);
    this.defaultValidator = defaultValidator.bind(this);
    this.isValidated = this.isValidated.bind(this);
  }

  componentDidMount() {
    document.title = 'TPCO Provider Assessment - Status Change';
    document.getElementById("page_title").innerHTML="TPCO Provider Assessment - Status Change";
  }
  _validateData(data) {
    return {
      status_changes: !(data.status_changes.includes("decreasing_hours") && data.status_changes.includes("increasing_hours")), 
      status_changes_other:
        !data.status_changes.includes("Other") ||
        data.status_changes_other !== ""
    };
  }

  async isValidated() {
    let status_changes = this._grabUserInput().status_changes;
    this.props.handleFocusReset();
    return this.defaultValidator(false, async function() {
      let confirmed = await checkEmpty(status_changes, "No status changes?");
      return confirmed;
    });
  }

  render() {
    return (
      <>
        <CheckBox
         errorClassName={this.state.errors.status_changes}
          title={"Please select any of the following categories that apply:"}
          name={"status_changes"}
          options={status_change_options}
          selectedOptions={this.state.data.status_changes}
          handleChange={this.handleInput}
          error={"Cannot select both Increasing Hours and Decreasing Hours"}
          touched={this.state.touched.status_changes}
        />{" "}
        {/* Status Change */}
        {this.state.data.status_changes.includes("Other") && (
          <Input
            errorClassName={this.state.errors.status_changes_other}
            inputType={"text"}
            name={"status_changes_other"}
            title={"Please specify other *"}
            value={this.state.data.status_changes_other}
            placeholder={"Specify other"}
            handleChange={this.handleInput}
            onBlur={this.handleBlur}
            error={"Please specify other status change"}
            touched={this.state.touched.status_changes_other}
          />
        )}{" "}
        {/* Status Change Other */}
        {"* Items are required"}
      </>
    );
  }
}

export default StatusChange;
